<template>
  <div class="sidebar-left">
    <div class="sidebar-overlay"></div>
    <div class="sidebar-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>